/**
 * This plugin was developed to enhance the user experience by ensuring returning users are reminded of items in their cart.
 * When a returning user visits the site and has items in their cart, the mini cart dialog will automatically open if the viewport is in lg (Desktop). This functionality aims to increase conversion rates by encouraging users to complete their purchases.
 *
 * For more details, please refer to the Jira ticket: GLOBAL15-101190.
 */

export default defineNuxtPlugin(({ hook }) => {
  const { totalItems } = useCartStore()
  const { DialogMiniCart } = useDialogsStore()
  const { lg } = useNuxtApp().$viewport

  hook('app:mounted', () => {
    const isReturningUser = localStorage.getItem('isReturningUser')
    const isPageRefreshed = sessionStorage.getItem('isPageRefreshed')
    if (!isReturningUser) localStorage.setItem('isReturningUser', 'true')
    else if (!isPageRefreshed && totalItems && lg) DialogMiniCart.open()
    sessionStorage.setItem('isPageRefreshed', 'true')
  })
})
